import React from "react";
import JSONData from "../../../content/assets/candidates.json"
import IndTable from "./tables/individualtable";
import IndTableCandRow from "./tables/individualtableCandidatesRow";


const CanCompIndividual= () => {
  const result = JSONData.candidates;
  const headings = JSONData.headings;

var elements=[];
var tbl = [];
var keys =Object.keys(result);



  elements.push(IndTableCandRow(  result, headings, "Individuals", "", true  ))

  return <div>{elements}</div>

  
}

export default CanCompIndividual
