import React from "react";
import JSONData from "../../../content/assets/candidates.json"
import IndTable from "./tables/individualtable";
import IndTableCandRow from "./tables/individualtableCandidatesRow";


const CanCompConstituency = () => {

  const headings = JSONData.headings;

  const result = JSONData.candidates.reduce( (acc, obj) => {
    acc[obj.constituency] = acc[obj.constituency] || [];
    acc[obj.constituency].push(obj);
    return acc;
}, {});

var elements=[];
var tbl = [];
var keys =Object.keys(result);


for(let a = 0; a < keys.length;a++)
{
    
  elements.push(IndTableCandRow( result[keys[a]], headings, keys[a], "constituency" ))
}

  return <div>{elements}</div>

  
}

export default CanCompConstituency
