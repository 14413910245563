import React from "react"
import { graphql } from "gatsby"

import Layout from "../layout"
import Seo from "../seo"
import CanCompConstituency from "./candidatescomponenconstituency"
import CanCompParty from "./candidatescomponent_party"
import { useState } from 'react'



class TableFilter extends React.Component {
  constructor({ passToParent, data, location }) {
    
    super({ data, location });
  }

  onTrigger = (event) => {
    this.props.passToParent();
}

  
  render() {
    return (
        <div>

            <input type="radio" id="contituency" name="filterby" value="contituency"></input>
            <label for="constituency">By Constiuency </label>
                <select>
                    <option>All</option>
                    <option>Belfast South</option>
                    <option>Belfast North</option>
                    <option>Belfast East</option>
                </select>

            <input type="radio" id="party" name="filterby" value="party"></input>
            <label for="party">By Party </label>
                <select>
                    <option>All</option>
                    <option>Alliance</option>
                    <option>DUP</option>
                    <option>Sinn Fein</option>
                </select>

            <input type="radio" id="individuals" name="filterby" value="individuals"></input>
            <label for="constituency">By Individuals </label>
                <select>
                    <option>All</option>
                    <option>John doe</option>
                    <option>Janice west</option>
                    <option>Jane East</option>
                </select>

        </div>
    );
  }
}

export default TableFilter