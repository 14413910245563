import React from "react";
import JSONData from "../../../content/assets/candidates.json";
import IndTable from "./tables/individualtable";
import IndTableCandRow from "./tables/individualtableCandidatesRow";


const CanCompParty = () => {
  const result = JSONData.candidates.reduce( (acc, obj) => {
    acc[obj.party] = acc[obj.party] || [];
    acc[obj.party].push(obj);
    return acc;
}, {});

const headings = JSONData.headings;

var elements=[];
var tbl = [];
var keys =Object.keys(result);


for(let a = 0; a < keys.length;a++)
{
    
  elements.push(IndTableCandRow(  result[keys[a]], headings, keys[a], "party", false  ))
}

  return <div className="party_table">{elements}</div>

  
}

export default CanCompParty
