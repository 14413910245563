import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import CanCompConstituency from "../components/candidates/candidatescomponenconstituency"
import CanCompParty from "../components/candidates/candidatescomponent_party"
import { useState } from 'react'
import TableFilter from "../components/candidates/tablefilter"
import CanCompIndividual from "../components/candidates/candidatescomponentinvidual"



class candidates extends React.Component {
  constructor({ data, location }) {
    
    super({ data, location });
    this.state = {
      mainFilter: "constituency"
    }
    
    this.siteTitle = data.site.siteMetadata.title;
    this.location = location;
  }

  handlefilterMain = e => {
    const value = e.target.value;

    this.setState({
      mainFilter: value
    });
  };
  

  render() {
    return (
      <Layout location={this.location} title={this.siteTitle}>
      <Seo title="candidates" />
      <p>Please expand the columns to see your local candidates</p>


      <div>

            <input type="radio" id="constituency" name="filterby" value="constituency" defaultChecked={this.state.mainFilter === "constituency"} onChange={this.handlefilterMain}></input>
            <label for="constituency">By Constituency </label>
                <select>
                    <option>All</option>
                    <option>Belfast South</option>
                    <option>Belfast North</option>
                    <option>Belfast East</option>
                </select>

            <input type="radio" id="party" name="filterby" value="party" onChange={this.handlefilterMain}></input>
            <label for="party">By Party </label>
                <select>
                    <option>All</option>
                    <option>Alliance</option>
                    <option>DUP</option>
                    <option>Sinn Fein</option>
                </select>

            <input type="radio" id="individual" name="filterby" value="individual" onChange={this.handlefilterMain}></input>
            <label for="constituency">By Individuals </label>
                <select>
                    <option>All</option>
                    <option>John doe</option>
                    <option>Janice west</option>
                    <option>Jane East</option>
                </select>

        </div>
      
      {this.state.mainFilter === "constituency" ?  <CanCompConstituency></CanCompConstituency> 
      : this.state.mainFilter === "party" ? <CanCompParty></CanCompParty> 
      : this.state.mainFilter === "individual" ?  <CanCompIndividual></CanCompIndividual>
      : null
      }
     
    </Layout>
    );
  }
}

export default candidates

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`