import React, { useState } from "react";


const IndTableCandRow = (dataD, headings, name, col_to_remove, isonlytable) => {
    const [seeMore, setSeeMore] = useState(false);
    var elements=[];


  
    var candidates = Object.assign({}, dataD); 
     
    var section_headings = Object.assign({}, headings.section_heading);

    for(let i = 0; i <  headings.section_headings.length; i++)
    {
      var this_section_headings;
      candidates = JSON.parse(JSON.stringify(dataD)) ;
      for(let z = 0; z < dataD.length; z++)
      {
        candidates[z].sections.splice(0,i)
        candidates[z].sections.splice(1,candidates[z].sections.length);
      }
      this_section_headings =  Object.assign({}, headings.section_headings[i]);

      elements.push(GenerateSection(candidates, headings, this_section_headings, name, seeMore, isonlytable, col_to_remove))
    }
    
      return (
        <div class="candidates-wrap">
        <div class="tablewrap">
          <div class="tablehead" onClick={() => setSeeMore(!seeMore)}>
              <h1>{name}</h1>
              {!isonlytable && (
              <button class="btn_expand">▼</button>
              )}
          </div>
          {  (seeMore || isonlytable) && (
          <div class="table-oflow">
           
              {elements}
          </div>
          )}
          </div>
          </div>
     )

}

export default IndTableCandRow

const GenerateSection = (candidates, headings, this_section_headings, name, seeMoreT, isonlytableT, col_to_remove) => {
  var elements=[];

    return (
      <React.Fragment>
         <table>
              <tr>
              {(col_to_remove === "constituency" || col_to_remove === "party") && ( <td class="table_section" colspan={(this_section_headings.questions.length +2)}>{this_section_headings.section_name}</td>)}
              {(col_to_remove !== "constituency" && col_to_remove === "party") && ( <td class="table_section" colspan={(this_section_headings.questions.length +3)}>{this_section_headings.section_name}</td>)}
              </tr>

              <colgroup>            
                <col class="table_name_const_party"/>{/* Name</col> */}

                {  (col_to_remove !== "party") && (
                  <col class="table_name_const_party"/>/*{headings.party}</col>*/
                )}

                {  (col_to_remove !== "constituency") && (
                  <col class="table_name_const_party"/>/*{headings.constituency}</col>*/
                )}

                {this_section_headings.questions.map((question) => {
                      return <col/>/*{question}</col>*/
                })}
              </colgroup>


              <tr>
              <td>Name</td>

              {  (col_to_remove !== "party") && (
                <td>{headings.party}</td>
              )}

              {  (col_to_remove !== "constituency") && (
                <td>{headings.constituency}</td>
              )}

              {this_section_headings.questions.map((question) => {
                    return <td>{question}</td>
              })}
              </tr>

                {candidates.map((candidate) => {
                              return  <tr>
                                          <td>{candidate.first_name} {candidate.last_name}</td>
                                          {  (col_to_remove !== "party") && (
                                            <td>{candidate.party}</td>
                                          )}

                                          {  (col_to_remove !== "constituency") && (
                                            <td>{candidate.constituency}</td>
                                          )}

                                          {candidate.sections.map((section) => {
                                                    return section.questions.map((question) => {
                                                                                return <td>{question.question_answer}</td>
                                                                                })
                                          })}
                                      </tr> 
                })}
         </table>
        </React.Fragment>)
}

